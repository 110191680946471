import ChildrenHubPage from "gatsby-theme-nurofen/src/templates/ChildrenHubPage/ChildrenHubPage"
import { graphql } from "gatsby"

export const query = graphql`
  query(
    $lang: String!
    $link: String = ""
    $articleTagId: Int
    $featuredProductsLinks: [String]
    $pageBannerImageSize: Int = 1560
    $pageIdRegex: String
    $extendedFeaturedArticlesByTagIds: [Int]
    $extendedFeaturedArticlesByLinks: [String]
  ) {
    ...FragmentExtendedFeaturedArticlesData
    featuredArticlesByTag: allUmbracoArticles(
      filter: {
        tags: { elemMatch: { id: { eq: $articleTagId } } }
        lang: { eq: $lang }
      }
    ) {
      nodes {
        ...FragmentFeaturedArticleData
      }
    }
    featuredProducts: allUmbracoProduct(
      filter: { link: { in: $featuredProductsLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        ...FragmentProductCard
      }
    }
    allUmbracoChildrenHub(
      filter: { lang: { eq: $lang }, link: { eq: $link } }
    ) {
      nodes {
        ...FragmentSeo
        lang
        pageName
        body {
          structure
          properties {
            ...FragmentBannerNZ
            ...FragmentDosageInfoSection
            ...FragmentSymptomsIllustrations
            ...FragmentFeaturedArticles
            ...FragmentFeaturedProducts
            ...FragmentChildrenProductTab
            ...FragmentFAQBanner
            ...FragmentExtendedFeaturedArticles
          }
        }
      }
    }
    carouselControls: siteSettings(lang: { eq: $lang }) {
      ariaLabelNext
      ariaLabelPrev
    }
    pageLangs: allUmbracoChildrenHub(
      filter: { umbracoId: { regex: $pageIdRegex } }
    ) {
      nodes {
        link
        lang
      }
    }
    ...FragmentCommonCompositions
  }
`

export default ChildrenHubPage
